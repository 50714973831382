<template>
  <v-card>
    <v-card-title>{{ $t('sidebar.chat.archive') }} BOK</v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="12" md="5">
          <v-row>
            <v-col cols="12">
              <label for="search">{{ $t('archive.user') }}</label>
              <v-autocomplete
                v-model="selectedUser"
                id="search"
                :items="users"
                :filter="customFilter"
                item-text="fullName"
                item-value="id"
                solo
                dense
                hide-details
                @change="getArchiveConversations(true)"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <label for="search">{{ $t('archive.search') }}</label>
              <v-text-field
                v-model="search"
                solo
                dense
                hide-details
                prepend-inner-icon="mdi-magnify"
                :disabled="!selectedUser"
                @input="searchDebounce"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <DatePicker
                v-model="dateFrom"
                :label="$t('archive.dateFrom')"
                @change="searchDebounce"
              />
            </v-col>
            <v-col cols="6">
              <DatePicker
                v-model="dateTo"
                :label="$t('archive.dateTo')"
                @change="searchDebounce"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <label for="threadType">{{ $t('archive.threadType') }}</label>
              <v-select
                id="threadType"
                solo
                :items="conversationThreadTypes"
                item-value="id"
                item-text="description"
                v-model="conversationType"
                @change="getArchiveConversations(true)"
              ></v-select>
            </v-col>
          </v-row>
        </v-col>
        <v-col v-if="activeUser" class="ml-5 mb-5 mt-auto">
          <v-row no-gutters class="mx-auto">
            <v-col cols="11" class="d-flex justify-space-between">
              <div class="d-flex align-center">
                <status-avatar :userId="activeUser.id" />
                <div class="d-flex flex-column ml-2">
                  <span class="text-body-2 font-weight-bold">{{
                    activeUser.fullName
                  }}</span>
                  <span class="text-body-2 info--text">{{ companyName }}</span>
                </div>
              </div>
            </v-col>
            <v-col cols="1">
              <v-menu offset-y left bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-on="on" v-bind="attrs" depressed icon>
                    <v-icon> mdi-dots-vertical </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item link @click="openExportToPDFDialog">
                    <v-list-item-title >
                      {{ $t('chatArchive.exportToPDF') }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <p v-if="activeConversation && !loadingThread">{{ activeConversation.threads[0].name }}</p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" md="5"
          ><div class="thread-list">
            <ThreadOnList
              v-for="thread in archiveConversations"
              :key="thread.conversation.threadId"
              :thread="thread"
              :active="activeThread == thread"
              @click="threadClick(thread)"
            ></ThreadOnList>
            <div v-if="load" class="text-center">
              <v-progress-linear
                v-intersect="getArchiveConversations"
                :width="3"
                :size="25"
                color="primary"
                indeterminate
              ></v-progress-linear>
            </div></div
        ></v-col>
        <v-col>
          <v-progress-linear indeterminate v-if="loadingThread">
          </v-progress-linear
          >
          <ChatThread
            class="chat-thread"
            v-if="activeConversation && !loadingThread"
            :conversation="activeConversation"
            :threadId="activeTheadId"
            :isArchive="true"
            :idToUseAsCurrentUser="selectedUser"
          ></ChatThread
        ></v-col> </v-row
    ></v-card-text>

    <v-dialog
      v-model="exportToPDFDialog"
      :fullscreen="$vuetify.breakpoint.mobile"
      max-width="600"
      persistent
    >
      <v-card class="d-flex flex-column justify-center">
        <v-card-title>
          {{ $t('chatArchive.exportToPDF') }}
        </v-card-title>
        <v-card-text>
          <v-row class="mt-4" no-gutters>
            <v-col cols="12">
              <label>{{ $t('chatArchive.dateFrom') }}</label>
              <v-menu
                v-model="fromDateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="exportToPDFVm.dateFrom"
                    :label="$t('chatArchive.dateFrom')"
                    :disabled="loading"
                    append-icon="mdi-calendar"
                    readonly
                    dense
                    outlined
                    single-line
                    hide-details
                    v-bind="attrs"
                    v-on="on"
                    @click:append="fromDateMenu = true"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="exportToPDFVm.dateFrom"
                  :first-day-of-week="1"
                  :max="
                    this.exportToPDFVm.dateTo
                      ? this.exportToPDFVm.dateTo
                      : new Date(Date.now()).toISOString()
                  "
                  @input="fromDateMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row class="mt-4" no-gutters>
            <v-col cols="12">
              <label>{{ $t('chatArchive.dateTo') }}</label>
              <v-menu
                v-model="toDateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="exportToPDFVm.dateTo"
                    :label="$t('chatArchive.dateTo')"
                    :disabled="loading"
                    append-icon="mdi-calendar"
                    readonly
                    dense
                    outlined
                    single-line
                    hide-details
                    v-bind="attrs"
                    v-on="on"
                    @click:append="toDateMenu = true"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="exportToPDFVm.dateTo"
                  :first-day-of-week="1"
                  :min="exportToPDFVm.dateFrom"
                  :max="new Date(Date.now()).toISOString()"
                  @input="toDateMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row class="mx-3 mb-3 justify-end" no-gutters>
            <v-btn
              class="mr-2"
              outlined
              depressed
              :disabled="loading"
              @click="closeExportToPDFDialog"
            >
              {{ $t('common.cancel') }}
            </v-btn>
            <v-btn
              color="primary"
              depressed
              :loading="loading"
              :disabled="loading"
              @click="exportConversationToPDF"
            >
              {{ $t('chatArchive.export') }}
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import ThreadOnList from './ThreadOnList'
import Services from '@/services'
import ChatThread from '@/chat/Window/ChatThread'
import StatusAvatar from '@/components/StatusAvatar.vue'
import DatePicker from '@/components/forms/DatePicker'
import moment from 'moment'
export default {
  components: {
    ThreadOnList,
    ChatThread,
    StatusAvatar,
    DatePicker
  },
  name: 'ArchiveBOK',
  data () {
    return {
      users: [],
      selectedUser: null,
      threads: [],
      activeThread: null,
      dateFrom: null,
      dateTo: null,
      loading: false,
      load: false,
      skipCount: 0,
      activeConversation: null,
      activeTheadId: null,
      search: null,
      archiveConversations: [],
      conversationType: null,
      loadingThread: false,
      searchDebounceTimeout: null,
      exportToPDFDialog: false,
      exportToPDFVm: {
        dateFrom: '',
        dateTo: ''
      },
      fromDateMenu: false,
      toDateMenu: false
    }
  },
  // GetUsersForCustomerServiceArchive
  async created () {
    await this.getUsers()
  },
  computed: {
    searchFromParams () {
      return this.$route.params.search
    },
    conversationThreadTypes () {
      return [
        {
          id: null,
          description: this.$t('archive.all')
        },
        ...this.$store.getters.enums.conversationThreadType.concat()
      ]
    },
    userId () {
      return this.$store.getters.userCommonData.id
    },
    activeUser () {
      return this.activeThread?.user
    },
    companyName () {
      const company = this.activeUser?.company
      if (!company) {
        return ''
      }
      return `${company.name} - ${company.displayId}`
    }
  },
  methods: {
    searchDebounce () {
      clearTimeout(this.searchDebounceTimeout)
      this.searchDebounceTimeout = setTimeout(async () => {
        await this.getArchiveConversations(true)
      }, 250)
    },
    async threadClick (thread) {
      this.activeThread = thread
      await this.getConversation(
        thread.conversation.id,
        thread.conversation.threadId
      )
    },
    async getUsers () {
      this.loading = true
      try {
        const { data } = await Services.get(
          '/Chat/GetUsersForCustomerServiceArchive'
        )
        this.users = data
      } finally {
        this.loading = false
      }
    },
    customFilter (item, queryText, itemText) {
      const textOne = item.fullName.toLowerCase()
      const searchText = queryText.toLowerCase()

      return textOne.indexOf(searchText) > -1
    },
    async getConversation (conversationId, threadId) {
      this.loading = true
      this.loadingThread = true
      if (!this.selectedUser) return

      try {
        const response = await Services.get(
          '/Chat/GetConversationCustomerService',
          {
            conversationId,
            threadId,
            userId: this.selectedUser
          }
        )
        this.activeConversation = response.data
        this.activeTheadId = threadId
      } finally {
        this.loading = false
        this.loadingThread = false
      }
    },
    async getArchiveConversations (refresh) {
      this.loading = true
      if (!this.selectedUser) return

      if (refresh === true) {
        this.load = false
        this.archiveConversations = []
        this.activeThread = null
        this.activeConversation = null
        this.activeTheadId = null
        this.skipCount = 0
      }
      if (this.load) {
        this.skipCount = this.archiveConversations.length
      }
      try {
        const { data } = await Services.get(
          'Chat/SearchArchiveCustomerService',
          {
            dateFrom: moment(this.dateFrom).startOf('day').format(),
            dateTo: moment(this.dateTo).endOf('day').format(),
            skipCount: this.skipCount,
            type: this.conversationType,
            search: this.search,
            userId: this.selectedUser
          }
        )
        if (data.results.length > 0) {
          data.results.forEach((resultItem) => {
            if (
              !this.archiveConversations.find(
                (el) =>
                  el.conversation.threadId === resultItem.conversation.threadId
              )
            ) {
              this.archiveConversations.push(resultItem)
            }
          })
          this.load = true
        } else {
          this.load = false
        }
      } finally {
        this.loading = false
      }
    },
    async exportConversationToPDF () {
      this.loading = true

      if (!this.selectedUser) return

      try {
        let dateTo = new Date(this.exportToPDFVm.dateTo)
        dateTo.setDate(dateTo.getDate() + 1)
        dateTo.setMilliseconds(dateTo.getMilliseconds() - 1)
        let dateFrom = this.exportToPDFVm.dateFrom
        if (!dateFrom) {
          dateFrom = null
        }
        if (!dateTo) {
          dateTo = null
        }
        await Services.post('/Chat/ExportToPDFForCustomerService', {
          userId: this.selectedUser,
          conversationId: this.activeThread.conversation.id,
          threadId: this.activeThread.conversation.threadId,
          dateFrom: dateFrom,
          dateTo: dateTo
        })
      } catch (e) {
      } finally {
        this.closeExportToPDFDialog()
        this.loading = false
      }
    },
    openExportToPDFDialog () {
      this.exportToPDFDialog = true
    },
    closeExportToPDFDialog () {
      this.exportToPDFDialog = false
      this.exportToPDFVm = {
        dateFrom: '',
        dateTo: ''
      }
    }
  },
  // async mounted () {
  //   await this.getArchiveConversations(true)
  //   if (this.searchFromParams) {
  //     this.search = this.searchFromParams
  //     this.searchDebounce()
  //   }
  // },
  destroyed () {
    clearTimeout(this.searchDebounceTimeout)
  }
}
</script>
<style scoped>
.chat-thread,
.thread-list {
  height: 600px;
  max-height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
